import { useMsal } from '@azure/msal-react'
import { DialogContent, Step, StepLabel, Stepper, Card, CardContent } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {
  acquireAccessToken,
  ErrorMessage,
  PrimaryButton,
  RegularButton,
  SnackbarVariants,
  SlideUpDialog
} from '@wavetronix/common-components'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { env } from '../index.js'
import getDeviceName from '../utils/GetDeviceName'
import handleAxiosError from '../utils/handleAxiosError'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

const DEFAULT_STATE = { step: 0, quantity: '', licenseDef: {}, user: {} }

export default function LicenseModal(props) {
  const [state, setState] = useState(DEFAULT_STATE)
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  useEffect(
    () =>
      setState(s => ({
        ...s,
        nextButtonDisabled:
          !state.licenseDef ||
          !state.user ||
          !(state.quantity && !state.quantity.includes('.') && !state.quantity.includes('-') && state.quantity !== '0')
      })),
    [state.licenseDef, state.user, state.quantity]
  )

  async function getLicenseDefs() {
    let token = await acquireAccessToken(instance, accounts, env)
    return await axios
      .get(`${env.urls.updatesURL}/api/v1/licensedefinitions?states=Published`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res.data)
      .catch(e => handleAxiosError(e, enqueueSnackbar))
  }

  const { data, refetch, error } = useQuery({ queryKey: ['licenseQuery'], queryFn: getLicenseDefs })

  useEffect(() => {
    if (state.licenseDef || (state.user && !props.isDetailView) || state.quantity) {
      window.onbeforeunload = e => {
        e.preventDefault()
        e.returnValue = ''
      }
    } else {
      window.onbeforeunload = undefined
    }
  })

  useEffect(() => {
    if (props.isDetailView && props.users.length > 0) {
      setState(s => ({ ...s, user: props.users[0] }))
    }
  }, [props.isDetailView, props.users])

  const onNext = () => setState(s => ({ ...s, step: 1 }))

  const onCancel = () => {
    if (!state.assigning) {
      props.onCancel()
      setState(DEFAULT_STATE)
      refetch()
    }
  }

  const onConfirm = async () => {
    setState(s => ({ ...s, assigning: true }))
    let key = enqueueSnackbar('Assigning...', SnackbarVariants.LOADING)
    let token = await acquireAccessToken(instance, accounts, env)
    await axios
      .post(
        `${env.urls.updatesURL}/api/v1/licenses?userId=${state.user.id}&licenseDefinitionId=${state.licenseDef.itemNumber}&count=${state.quantity}`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(res => {
        if (res.status === 200) {
          enqueueSnackbar(`License${state.quantity > 1 ? 's' : ''} successfully assigned`, SnackbarVariants.SUCCESS)
          onCancel()
        }
      })
      .catch(e => {
        handleAxiosError(e, enqueueSnackbar)
        setState(s => ({ ...s, assigning: false }))
      })
      .finally(() => closeSnackbar(key))
  }

  if (error) {
    return <ErrorMessage error={error} />
  }

  return (
    <SlideUpDialog
      id='licenseModal'
      open={props.visible}
      onClose={onCancel}
      maxWidth={'sm'}
      fullWidth={true}
      title={<h3 style={{ margin: 0 }}>Assign License</h3>}
      actions={
        state.step === 0 ? (
          <>
            <PrimaryButton id='nextStepButton' disabled={state.nextButtonDisabled} onClick={onNext}>
              Next
            </PrimaryButton>
          </>
        ) : (
          <>
            <div style={{ width: '100%' }}>
              <RegularButton
                id='backStepButton'
                onClick={() => setState(s => ({ ...s, step: 0 }))}
                style={{ float: 'left' }}
                disabled={state.assigning}
              >
                Back
              </RegularButton>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end'
                }}
              >
                <PrimaryButton id='confirmStepButton' onClick={onConfirm} disabled={state.assigning}>
                  Confirm
                </PrimaryButton>
              </div>
            </div>
          </>
        )
      }
    >
      <DialogContent>
        <Stepper activeStep={state.step} sx={{ margin: '20px 0px 20px 0px' }}>
          <Step>
            <StepLabel>Assign</StepLabel>
          </Step>
          <Step>
            <StepLabel>Confirm</StepLabel>
          </Step>
        </Stepper>
        {state.step === 0 ? (
          <>
            <Autocomplete
              id='licenseDefAutocomplete'
              options={
                data
                  ? data.map(ld => ({
                      label: `${ld.name} #${ld.itemNumber}`,
                      value: ld.itemNumber,
                      baseProductId: ld.baseProductId,
                      itemNumber: ld.itemNumber
                    }))
                  : []
              }
              inputValue={state.licenseDef.label ? state.licenseDef.label : ''}
              onChange={(event, value, reason) => {
                //used for selecting from the list
                setState(f => ({ ...f, licenseDef: { ...f.licenseDef, ...value } }))
              }}
              onInputChange={(event, value, reason) => {
                // used when typing anything and then selecting
                if (reason !== 'reset') {
                  setState(f => ({ ...f, licenseDef: { ...f.licenseDef, label: value ? value : '' } }))
                }
                if (reason === 'reset' && value !== '') {
                  setState(f => ({ ...f, licenseDef: { ...f.licenseDef, label: value ? value : '' } }))
                }
                if (reason === 'reset' && state.licenseDef === '') {
                  setState(f => ({ ...f, licenseDef: { ...f.licenseDef, label: '' } }))
                }
              }}
              isOptionEqualToValue={(option, someValue) => option.value === someValue.value}
              renderInput={params => (
                <TextField {...params} label='Search licenses' size='small' style={{ width: '100%', marginTop: '15px' }} />
              )}
            />
            {props.isDetailView ? (
              <>
                <TextField
                  id='userEmailTextField'
                  size='small'
                  style={{ width: '100%', marginTop: 15 }}
                  label='User'
                  value={props.users[0].email}
                  required
                  variant='outlined'
                  disabled
                />
              </>
            ) : (
              <Autocomplete
                id='userAutocomplete'
                options={
                  props.users
                    ? props.users.map(u => ({
                        label: `${u.givenName} ${u.surname} - ${u.email}`,
                        givenName: u.givenName,
                        surname: u.surname,
                        email: u.email,
                        company: u.company,
                        id: u.id
                      }))
                    : []
                }
                inputValue={state.user.label ? state.user.label : ''}
                onChange={(event, value, reason) => {
                  //used for selecting from the list
                  setState(f => ({ ...f, user: { ...f.user, ...value } }))
                }}
                onInputChange={(event, value, reason) => {
                  // used when typing anything and then selecting
                  if (reason !== 'reset') {
                    setState(f => ({ ...f, user: { ...f.user, label: value ? value : '' } }))
                  }
                  if (reason === 'reset' && value !== '') {
                    setState(f => ({ ...f, user: { ...f.user, label: value ? value : '' } }))
                  }
                  if (reason === 'reset' && state.user === '') {
                    setState(f => ({ ...f, user: { ...f.user, label: '' } }))
                  }
                }}
                isOptionEqualToValue={(option, someValue) => option.id === someValue.id}
                renderInput={params => {
                  return <TextField {...params} label='Search users' size='small' style={{ width: '100%', marginTop: '15px' }} />
                }}
              />
            )}
            <TextField
              id='licenseQuantityTextField'
              style={{ width: '100%', marginTop: '15px' }}
              label='Quantity'
              onChange={e => setState(s => ({ ...s, quantity: e.target.value }))}
              value={state.quantity}
              required
              type='number'
              min='1'
              variant='outlined'
              size='small'
            />
          </>
        ) : (
          <>
            <div style={{ fontSize: '13px' }}>
              <h3>You are assigning {state.quantity ? state.quantity : 1} of the following license:</h3>
              <Card>
                <CardContent>
                  <p>License Name: {state.licenseDef ? state.licenseDef.label : ''}</p>
                  <p>Item Number: {state.licenseDef ? state.licenseDef.itemNumber : ''}</p>
                  <p>Base Product ID: {state.licenseDef ? getDeviceName(state.licenseDef.baseProductId) : ''}</p>
                </CardContent>
              </Card>
              <h3>To the following user:</h3>
              <Card>
                <CardContent>
                  <p>Name: {state.user ? `${state.user.givenName} ${state.user.surname}` : ''}</p>
                  <p>Email: {state.user ? state.user.email : ''}</p>
                  <p>Company: {state.user ? state.user.company : ''}</p>
                </CardContent>
              </Card>
              <h3>Would you like to confirm this transaction?</h3>
            </div>
          </>
        )}
      </DialogContent>
    </SlideUpDialog>
  )
}
