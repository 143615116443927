import { useMsal } from '@azure/msal-react'
import { Card, CardContent, CircularProgress, Grid, TextField } from '@mui/material'
import { AddOutlined } from '@mui/icons-material'
import {
  acquireAccessToken,
  CenteredDiv,
  CustomDataGrid,
  ErrorMessage,
  RegularButton,
  WtxColors
} from '@wavetronix/common-components'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'
import { env } from '../index.js'
import compareAlphabetically from '../utils/compareAlphabetically'
import handleAxiosError from '../utils/handleAxiosError'
import LicenseV2Modal from './LicenseV2Modal'
import BackButton from './BackButton'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

function groupLicensesByItemNumber(list) {
  const resultObject = list.reduce((acc, value) => {
    // Group initialization
    if (!acc[value.licenseDefinition.id]) {
      acc[value.licenseDefinition.id] = []
    }

    // Grouping
    acc[value.licenseDefinition.id].push(value)
    return acc
  }, {})

  const resultList = Object.entries(resultObject).map(x => ({
    id: x[0],
    licenses: x[1],
    name: x[1][0].licenseDefinition.name,
    description: x[1][0].licenseDefinition.description
  }))

  return resultList
}

export default function UserDetailViewV2() {
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      sortComparator: compareAlphabetically,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'itemNumber',
      headerName: 'Item Number',
      sortComparator: compareAlphabetically,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'description',
      headerName: 'Description',
      sortComparator: compareAlphabetically,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'active',
      headerName: 'Active',
      sortComparator: (a, b) => a.active - b.active,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'available',
      headerName: 'Available',
      sortComparator: (a, b) => a.available - b.available,
      disableColumnMenu: true,
      flex: 1
    }
  ]

  const location = useLocation()
  let querystring = ''
  if (location.search.length > 0 && location.search[0] === '?') {
    querystring = location.search.substring(1)
  }
  let userId = qs.parse(querystring).id
  const { enqueueSnackbar } = useSnackbar()
  const { instance, accounts } = useMsal()

  const getUserData = async () => {
    let token = await acquireAccessToken(instance, accounts, env)
    let userInfo = axios
      .get(`${env.urls.gatekeeperURL}/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res.data)
    let userLicenses = axios
      .get(`${env.urls.updatesURL}/api/v2/licensesv2?userId=${userId}&state=All`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res.data)
    return await Promise.all([userInfo, userLicenses])
      .then(values => {
        const groupedList = groupLicensesByItemNumber(values[1])
        const licenseSummary = groupedList.map((g, i) => {
          return {
            name: g.name,
            itemNumber: g.id,
            description: g.description,
            active: g.licenses.filter(u => u.activation.isActive).length,
            available: g.licenses.filter(u => !u.activation.isActive).length,
            key: `grouped-licenses-${i}`,
            id: g.id
          }
        })
        return { userInfo: values[0], userLicenses: licenseSummary }
      })
      .catch(e => handleAxiosError(e, enqueueSnackbar))
  }

  const { isLoading, data, refetch, error, remove } = useQuery({ queryKey: ['getUserData'], queryFn: getUserData })
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => remove, [remove])

  if (error) {
    return <ErrorMessage error={error} />
  }

  return isLoading ? (
    <CenteredDiv>
      <CircularProgress />
    </CenteredDiv>
  ) : (
    <div style={{ margin: '24px' }}>
      <BackButton path={'..'} previous={'Licenses'} />

      <Grid container spacing={2}>
        <Grid xl={4} lg={4} md={12} sm={12} xs={12} item>
          <Card>
            <CardContent>
              <TextField
                label='First Name'
                style={{ width: '100%', marginTop: '5px' }}
                value={data.userInfo ? `${data.userInfo.givenName} ${data.userInfo.surname}` : ''}
                disabled
                size={'small'}
              />
              <TextField
                label='Email'
                style={{ width: '100%', marginTop: '15px' }}
                value={data.userInfo ? data.userInfo.email : ''}
                disabled
                size={'small'}
              />
              <TextField
                label='Company'
                style={{ width: '100%', marginTop: '15px' }}
                value={data.userInfo ? data.userInfo.company : ''}
                disabled
                size={'small'}
              />
              <CenteredDiv>
                <RegularButton
                  id='assignLicenseModalButton'
                  style={{ marginTop: 24 }}
                  startIcon={<AddOutlined style={{ color: WtxColors.GROWTH_GREEN }} />}
                  onClick={() => setModalVisible(true)}
                >
                  Assign License
                </RegularButton>
              </CenteredDiv>
            </CardContent>
          </Card>
        </Grid>

        <Grid xl={8} lg={8} md={12} sm={12} xs={12} item>
          <CustomDataGrid
            rows={data.userLicenses}
            autoHeight
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[10, 20, 50, 100]}
            disableSelectionOnClick
            loading={isLoading}
            disableExtendRowFullWidth={true}
          />
        </Grid>
      </Grid>

      <LicenseV2Modal
        onCancel={() => {
          refetch()
          setModalVisible(false)
        }}
        visible={modalVisible}
        users={[data.userInfo]}
        loading={isLoading}
        isDetailView={true}
      />
    </div>
  )
}
