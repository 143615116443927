import { useNavigate } from 'react-router-dom'
import { React } from 'react'
import { WtxColors } from '@wavetronix/common-components'
export default function BackButton(props) {
  const navigate = useNavigate()
  return (
    <p>
      <span
        id='backButton'
        onClick={() => navigate(props.path)}
        style={{
          marginLeft: 15,
          cursor: 'pointer',
          color: WtxColors.IQ_BLUE
        }}
      >{`Back to ${props.previous}`}</span>
    </p>
  )
}
